.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;

  .loginForm {
    background: white;
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 70%;

      .invalidFeedback {
        color: red;
        display: block;
      }
    }

    .invalidInput {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 70%;
    }

    h1 {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .formGroup {
      margin-bottom: 1rem;
      position: relative; // Add position relative to form group

      .formControl.isInvalid {
        border-color: #dc3545;
      }

      .showPasswordToggle {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    .btnPrimary {
      width: 100%;
      padding: 0.5rem;
    }

    .registerLink {
      display: block;
      margin-top: 1rem;
      text-align: center;
    }
  }
}

.registerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;

  .registerForm {
    background: white;
    height: 800px; // Adjusted height to fit additional fields
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80%;
    }

    h1 {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .formGroup {
      margin-bottom: 1rem;
    }

    .formControl.isInvalid {
      border-color: #dc3545;
    }

    .invalidFeedback {
      display: block;
    }

    .btnPrimary {
      width: 100%;
      padding: 0.5rem;
    }

    .loginLink {
      display: block;
      margin-top: 1rem;
      text-align: center;
    }
  }
}

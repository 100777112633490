.userInformation {
  .userCard {
    //Set fix height for card
    height: 100%;
  }

  .card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }

  .card-body {
    .card-title {
      font-size: 1.25rem;
      font-weight: bold;
    }

    .card-text {
      margin-bottom: 1rem;
    }
  }
}

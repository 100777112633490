.sidebar {
  margin: 0;
  padding-top: 150px;
  width: 300px;
  background-color: #111a32;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: rgb(255, 255, 255);
  font-size: 20px;
  padding: 16px;

  text-decoration: none;
  transition: 0.3s;
}

.sidebar a.active {
  background-color: #324a8f;
  transition: 0.3s;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #324a8f;
  color: white;
  transition: 0.3s;
}

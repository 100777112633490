/* UI/Modal.module.scss */

/* Modal container */
.modal {
  display: none; /* Modalı varsayılan olarak gizle */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
  z-index: 1000; /* Modalın diğer öğelerin üstünde olduğundan emin olun */

  /* Modal içeriği */
  .modalContent {
    padding: 15px;
    background-color: #fff;
    z-index: 2;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 30rem;
    text-align: center;
    justify-content: center;

    /* Kapatma düğmesi (sağ üst köşe) */
    .close {
      padding: 0;
      margin: 0;
      position: absolute;
      top: -10px;
      right: 5px;
      cursor: pointer;
      font-size: 30px;
      height: 30px;
      width: 30px;
      &:hover {
        color: #aaa;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      0,
      0,
      0,
      0.2
    ); /* Arkaplanı hafifçe karartın */ // Arkaplan rengi ve opaklık
    z-index: 1;
  }
}

/* Modal gösterildiğinde */
.show {
  display: flex;
}
